.footer {
  background: #141414;
  padding: 48px 24px 24px;
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
  }

  .section {
    margin-bottom: 24px;

    .title {
      display: block;
      color: #ffffff;
      font-size: 16px;
      margin-bottom: 16px;
    }

    a {
      color: rgba(255, 255, 255, 0.65);
      transition: color 0.3s;
      
      &:hover {
        color: #2d9cdb;
      }
    }
  }

  .socialLinks {
    a {
      font-size: 20px;
      
      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .divider {
    background: rgba(255, 255, 255, 0.1);
    margin: 32px 0 24px;
  }

  .bottom {
    text-align: center;
    
    a {
      color: rgba(255, 255, 255, 0.45);
      
      &:hover {
        color: #2d9cdb;
      }
    }
  }
}
