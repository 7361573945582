.container {

  > section {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 64px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
  }

  > section::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000, #1677ff, #000);
    animation: animate 5s linear infinite;
  }

  @keyframes animate {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  > section > span {
    position: relative;
    display: block;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    background: #181818;
    z-index: 2;
    transition: 1.5s;
  }

  > section > span:hover {
    background: #1677ff;
    transition: 0s;
  }

  .register {
    position: absolute;
    width: 630px;
    background: #f1f1fe;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 9);

    form {
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  section > span {
    width: calc(10vw - 2px);
    height: calc(10vw - 2px);
  }
}

@media (max-width: 600px) {
  section > span {
    width: calc(20vw - 2px);
    height: calc(20vw - 2px);
  }

}
