.container {
  min-height: calc(100vh - 64px - 65px);
  width: 1080px;
  margin: 0 auto;
  display: flex;

  .postTitle {
    font-size: 30px;
    line-height: 1.3;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .postMeta {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1;

    .postMetaContent {
      display: flex;
      align-items: center;
      gap: 8px;

      .postDate {
        position: relative;
        padding-right: 18px;
        
        &::after {
          content: "•";
          position: absolute;
          right: 6px;
          color: currentColor;
          opacity: 0.6;
        }
      }

      .postLength {
        font-size: 12px;
      }

      div {
        color: gray;
      }
    }
  }



  .postContent {
    line-height: 1.9;

    p {
      text-align: start;
    }

    strong {
      width: 100%;
      display: inline-block;
      text-align: start;
    }
  }

  .uTextFormat {
    font-size: 16px;
  }

}