.container {
  padding: 24px;
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);

  h3 {
    text-align: start;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.tags {
  width: 100%;
  margin-top: 12px;
  .checkIcon {
    color: #52c41a;
  }
}

.detailCard {
  margin-top: 24px;
}

.securityList {
  .securityItem {
    display: flex;
    align-items: flex-start;
    padding: 16px 0;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .icon {
      color: #1677ff;
      font-size: 18px;
      margin-right: 12px;
      margin-top: 3px;
    }

    .content {
      flex: 1;

      .label {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        text-align: left;
      }

      .desc {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        text-align: left;
        .link {
          margin-left: 8px;
        }
      }
    }
  }
}

.verifiedIcon {
  color: #52c41a;
}