.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    padding: 0 12px;
  }

  .content {

    .title {
      display: flex;
      margin-top: 24px;
      flex-direction: row;
      padding: 0 16px;

      .mch {
        display: flex;
        flex: 3;
      }

      .info {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
}