.container {
  line-height: 1;
  margin-right: 16px;
  text-align: center;

  h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
    font-size: 32px;
    color: #1777FF;
  }
}