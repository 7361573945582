.container {
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
}

.hero {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(45, 156, 219, 0.1), transparent 50%);
  }
}

.heroContent {
  text-align: center;
  z-index: 1;
}

.title {
  font-size: 4rem !important;
  background: linear-gradient(90deg, #fff 0%, #2d9cdb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px !important;
}

.highlight {
  color: #2d9cdb;
}

.subtitle {
  font-size: 1.5rem;
  color: #ffffff80;
  display: block;
  margin-bottom: 48px;
}

.ctaButtons {
  margin-top: 32px;
}

.features {
  padding: 80px 20px;
  background: rgba(255, 255, 255, 0.02);
}

.featureCard {
  height: 100%;
  text-align: center;
  padding: 40px 24px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .icon {
    font-size: 48px;
    color: #2d9cdb;
    margin-bottom: 24px;
  }

  h3 {
    color: #fff;
    margin-bottom: 16px;
  }

  span {
    color: #ffffff80;
  }
}

.stats {
  padding: 80px 20px;
  text-align: center;
  background: linear-gradient(180deg, transparent, rgba(45, 156, 219, 0.1));

  h2 {
    color: #2d9cdb;
    margin-bottom: 8px !important;
  }

  span {
    color: #ffffff80;
    font-size: 1.1rem;
  }
}

.cta {
  padding: 80px 20px;
  text-align: center;
}

.ctaCard {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 40px;
  background: rgba(45, 156, 219, 0.1);
  border: 1px solid rgba(45, 156, 219, 0.2);
  
  .ctaIcon {
    font-size: 64px;
    color: #2d9cdb;
    margin-bottom: 24px;
  }

  h2 {
    color: #fff;
    margin-bottom: 16px !important;
  }

  span {
    color: #ffffff80;
    font-size: 1.1rem;
    display: block;
  }

  .registerButton {
    margin-top: 32px;
    span {
      color: #fff;
    }
  }
}
