.container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;

  .tabs {
    display: flex;
    margin: 12px;
  }
}