.container {
  padding: 24px;
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
}

.mainCard {
  margin-bottom: 24px;

  .header {
    text-align: center;
    margin-bottom: 48px;

    h2 {
      margin-bottom: 8px;
    }

    h3 {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }

  .tradeForm {
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 32px;

    .inputGroup {
      width: 100%;

      h4 {
        margin-top: 0;
        text-align: left;
      }
    }
  }
}

.stepsCard {
  padding-bottom: 24px;
  h3 {
    text-align: center;
    margin-bottom: 48px;
  }

  .steps {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding: 0 48px;

    .step {
      flex: 1;
      text-align: center;

      .icon {
        font-size: 32px;
        margin-bottom: 16px;
        :global(.anticon) {
          font-size: 32px;
          color: #1677ff;
        }
      }

      h4 {
        margin-bottom: 8px;
      }
    }
  }
}
