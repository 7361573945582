.container {
  padding: 24px;
  background-color: #f5f5f5;
  width: 100%;
  min-height: calc(100vh - 64px);
}

.overview {
  margin-bottom: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h3 {
      margin: 0;
    }
  }

  .balance {
    margin-bottom: 24px;

    h2 {
      margin: 0;

      .unit {
        font-size: 16px;
        margin-left: 8px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .tags {
    display: flex;
    gap: 8px;
  }
}

.assets {

  .fullName {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  .increase {
    color: #52c41a;
  }

  .decrease {
    color: #ff4d4f;
  }
}
