.header {
  display: flex;
  align-items: center;
  padding: 0 36px;

  .logo {
    display: flex;
    height: 100%;
  }
  .nav {
    display: flex;
    flex: 1;
  }

  .right {
    float: right;
    display: flex;
    align-items: center;

    .email {
      color: white;
    } 

    .localePicker {
      margin-left: 16px;
    }
  }

}