.container {
//   min-height: calc(100vh - 64px);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #f5f5f5;
//   padding: 24px;

  padding: 24px;
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
}

.card {
  width: 100%;
  text-align: center;
  padding: 48px 24px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  
  .icon {
    font-size: 64px;
    color: #1677ff;
  }

  .description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    max-width: 400px;
    margin: 0 auto;
  }

  .features {
    display: flex;
    justify-content: center;
    gap: 48px;
    margin: 24px 0;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      color: rgba(0, 0, 0, 0.65);

      :global(.anticon) {
        font-size: 24px;
        color: #1677ff;
      }

      span {
        font-size: 16px;
      }
    }
  }

  :global(.ant-btn) {
    height: 48px;
    font-size: 16px;
    padding: 0 32px;
  }
}
