.container {
  padding: 24px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.description {
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
}

.loading {
  padding: 48px 0;
  text-align: center;
}

.paymentList {
  .paymentItem {
    margin-bottom: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;

    .paymentHeader {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      background-color: #f5f7fa;

      .paymentIcon {
        font-size: 20px;
        margin-right: 8px;
      }

      .label {
        font-weight: 500;
        color: #000000;
      }

      .actions {
        margin-left: auto;
      }

      // 不同支付方式的头部背景色
      &.type1 {
        background-color: #e6f4ff;
        .paymentIcon {
          color: #1677ff;
        }
      }

      &.type2 {
        background-color: #f6ffed;
        .paymentIcon {
          color: #52c41a;
        }
      }

      &.type0 {
        background-color: #fff7e6;
        .paymentIcon {
          color: #faad14;
        }
      }
    }

    .paymentContent {
      padding: 16px;
      background: #ffffff;

      .row {
        display: flex;
        margin-bottom: 12px;
        
        :first-child {
          width: 100px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
