.content {
  padding: 0 16px;
}

.warning {
  background-color: #fff7e6;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 24px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.address {
  .addressInput {
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .actions {
    display: flex;
    gap: 16px;
  }
}

.tips {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
