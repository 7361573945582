.container {
  display: flex;
  padding: 0 16px;
  flex-direction: column;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: row;

  .basicInfo {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: start;
    //background-color: cadetblue;
  }

  .priceInfo {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: #61dafb;
  }

  .amountInfo {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    //background-color: antiquewhite;
  }

  .paymentInfo {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-color: blue;
  }

  .actionButton {
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}