.container {
  padding: 24px;
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
}

.mainCard {
  margin-bottom: 24px;
  .header {
    text-align: center;
    margin-bottom: 48px;
    margin-top: 48px;

    h2 {
      margin: 0;
    }
  }
}

.inviteSection {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 48px;

  .inputGroup {
    margin-bottom: 16px;

    .copyIcon {
      color: #1677ff;
      cursor: pointer;

      &:hover {
        color: #4096ff;
      }
    }
  }
}

.explain {
  .stepsSection {
    margin-bottom: 48px;

    h3 {
      text-align: center;
      margin-bottom: 32px;
    }

    .steps {
      display: flex;
      justify-content: space-around;
      text-align: center;

      .step {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 0 16px;

        .stepIcon {
          font-size: 32px;
          color: #1677ff;
        }
      }
    }
  }

  .rulesSection {
    h3 {
      margin-bottom: 24px;
    }

    .rules {
      color: rgba(0, 0, 0, 0.65);
      padding-left: 160px;
      line-height: 1.8;

      li {
        margin-bottom: 8px;
        text-align: start;
      }
    }
  }

}

