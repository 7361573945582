.container {
  padding: 24px;
  width: 100%;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
}

.header {
  margin-bottom: 24px;
  text-align: left;

  .subtitle {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-top: 12px;
  }

  h3 {
    margin: 0;
  }
}

.content {
  .tableHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .rules {
      cursor: pointer;
      
      &:hover {
        color: #1677ff;
      }
    }
  }

  :global {
    .ant-table-thead > tr > th {
      background-color: #fafafa;
      font-weight: 500;
      text-align: left;
    }

    .ant-table-tbody > tr > td {
      text-align: left;
    }
  }
}
