.container {
  width: 100%;
  margin: 0 auto;
  padding: 24px;
}

.section {
  margin-bottom: 48px;
  
  h2 {
    margin-bottom: 32px;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 48px;
      height: 4px;
      background: #2d9cdb;
      border-radius: 2px;
    }
  }
}

.introduction {
  font-size: 16px;
  line-height: 1.8;
}

.features {
  margin: 48px 0;
}

.featureCard {
  text-align: center;
  padding: 32px;
  height: 100%;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }
  
  .icon {
    font-size: 48px;
    color: #2d9cdb;
    margin-bottom: 24px;
  }
  
  h4 {
    margin-bottom: 16px;
  }
}

.stats {
  margin-top: 48px;
  text-align: center;
  
  h3 {
    color: #2d9cdb;
    margin-bottom: 8px;
  }
}

.joinUs {
  .content {
    h3 {
      margin-bottom: 24px;
    }
    
    .benefits {
      margin: 24px 0 32px;
      padding-left: 20px;
      
      li {
        margin-bottom: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  
  .positions {
    h4 {
      margin-bottom: 24px;
    }
    
    .positionCard {
      cursor: pointer;
      transition: all 0.3s ease;
      
      &:hover {
        transform: translateX(5px);
        border-color: #2d9cdb;
      }
      
      h5 {
        margin-bottom: 8px;
      }
    }
  }
}

.culture {
  .cultureCard {
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
      
      .imageWrapper img {
        transform: scale(1.05);
      }
    }
    
    .imageWrapper {
      height: 300px;
      overflow: hidden;
      margin-bottom: 24px;
      border-radius: 8px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
    }
    
    h3 {
      margin: 16px 0 8px;
      color: #2d9cdb;
    }
  }
  
  .vision {
    text-align: center;
    margin-top: 64px;
    padding: 48px;
    background: linear-gradient(135deg, rgba(45, 156, 219, 0.1), rgba(45, 156, 219, 0.05));
    border-radius: 16px;
    
    h3 {
      color: #2d9cdb;
      margin-bottom: 16px;
    }
    
    h4 {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
