.container {

}
.content {
  display: flex;
  margin-top: 24px;

  .sellerInfo{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: black;
    border-radius:  8px 0 0 5px;
    padding: 12px;

    .basicInfo {
      display: flex;
      align-items: center;

      .textBox {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
      }
    }

    .entrustWrapper {
      display: flex;
      flex-direction: column;
      padding: 8px 4px;

      .entrust {
        display: flex;
        margin: 4px 0;
        justify-content: space-between;
      }
    }

    .remarkWrapper {
      margin-top: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  .tradeForm {
    padding: 12px;
    display: flex;
    flex: 1;
    background-color: aliceblue;
    border-radius:  0 8px 8px 0;
  }
}